<template>
  <div id="header-bar" class="fixed top-0 z-[1000] w-full">
    <template v-if="!isOpenInPcXcsCanvas">
      <PcHeader />
      <XsHeader />
    </template>
    <div v-else class="flex h-[66px] items-center justify-center" style="background: rgba(255, 255, 255, 0.92); backdrop-filter: blur(16px)">
      <h1 class="hidden"><a href="//designfind.com/" @click.prevent="">DesignFind</a></h1>
      <div class="inline-flex items-center">
        <Logo class="h-10" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PcHeader from './PcHeader.vue';
import XsHeader from './XsHeader.vue';
import Logo from '~/layouts/default/header/Logo.vue';

const route = useRoute();

// XCS 画布左侧工具栏打开 ALL 页面
const isOpenInPcXcsCanvas = computed(() => {
  const query = route.query;
  return query.openFrom === 'xcs' && isInPCApp() && (route.path.startsWith('/designs') || (route.path === '/all' && query.types === 'designs'));
});
</script>

<style lang="less"></style>
