<template>
  <div class="item relative mb-4 cursor-pointer mobile:mb-0 mobile:flex mobile:!w-full mobile:items-center" @click="onChange">
    <div class="absolute left-2 top-2 mobile:static">
      <a-checkbox v-model:checked="checked" />
    </div>
    <div class="mobile:ml-6 mobile:flex mobile:gap-3">
      <div class="overflow-hidden rounded-lg mobile:w-[100px] mobile:flex-shrink-0">
        <img class="w-full object-cover" :src="getOssProcessedImage(cover, 'm_mfit,w_180,h_130', 'webp')" :alt="item.title" />
      </div>
      <div class="mt-3 mobile:mt-0 mobile:flex mobile:flex-col mobile:justify-center mobile:gap-2">
        <div class="one-line-ellipsis use-word-break text-level-4 text-text-primary">{{ item.title }}</div>
        <span class="inline-flex w-fit flex-grow-0 rounded bg-background-2 px-1 py-0.5 text-level-7 text-text-tertiary">{{ item.type }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type PropsType = {
  item: any;
};
const props = withDefaults(defineProps<PropsType>(), {
  item: () => ({}),
});
type EmitsType = {
  (eventName: 'change', value: Record<string, any>): void;
};
const emits = defineEmits<EmitsType>();

const cover = computed(() => {
  return props.item.cover;
});

const checked = ref(false);
const onChange = () => {
  checked.value = !checked.value;
  emits('change', { checked: checked.value, item: props.item });
};

const reset = (id?: number) => {
  if (props.item.id === id) {
    return;
  }
  checked.value = false;
};

defineExpose({
  reset,
});
</script>

<style lang="less" scoped></style>
