<template>
  <div id="notice-icon" class="notice-message-list cursor-pointer">
    <a-popover
      v-model:open="noticeShow"
      :auto-adjust-overflow="false"
      placement="bottomRight"
      trigger="click"
      :arrow="false"
      overlay-class-name="notice-message-list-popover"
      :get-popup-container="(triggerNode: any) => triggerNode.parentNode"
    >
      <a-badge
        class="custom-badge flex h-10 w-10 items-center justify-center rounded-full bg-[#f0f1f5] text-[40px]"
        :count="noticeStore.count"
        :overflow-count="99"
      >
        <icon-font class="icon-message text-[26px] text-text-primary" type="icon-message-line" />
      </a-badge>
      <template #content>
        <a-config-provider>
          <template #renderEmpty>
            <a-empty :image="emptyImg" class="empty-img">
              <template #description>
                <span>No Message</span>
              </template>
            </a-empty>
          </template>
          <a-list :data-source="noticeStore.noticeList" :loading="isLoading" class="relative">
            <template #renderItem="{ item }">
              <a-list-item class="notice-message-list-item w-[430px]">
                <div class="flex flex-col gap-2">
                  <div class="notice-list-item-wrap-1 flex items-center justify-between">
                    <AuthorUserImg
                      class="user-img"
                      img-size="32px"
                      :user-id="item.createdBy"
                      :user-img="item.createdByHeadpic"
                      :user-name="item.createdByName"
                    />
                    <span class="time text-uni-3">
                      {{ moment(item.createdAt * 1000).format('YYYY/MM/DD') }}
                    </span>
                  </div>
                  <NuxtLink
                    class="two-line-ellipsis block pl-10 text-text-secondary"
                    :title="`${item.title || ''} ${item.content}`"
                    :to="item.localUrl"
                    :target="item.localTarget"
                    @click="handleNoticeClick(item)"
                  >
                    <span v-if="item.title" class="font-sans-semibold text-level-4 text-text-secondary">{{ item.title }}&nbsp;</span>
                    <span class="font-sans-semibold text-level-4 text-text-primary">
                      {{ item.content }}
                    </span>
                  </NuxtLink>
                </div>
              </a-list-item>
            </template>
            <template #loadMore>
              <NuxtLink
                :to="'/notice-center?active=all'"
                :class="'load-more-wrap block h-[50px] cursor-pointer border-0 border-t border-solid border-[rgba(0,0,0,0.16)] text-center font-sans-semibold text-level-5 leading-[50px] text-text-tertiary hover:text-text-primary'"
                @click="handleCloseNotice"
              >
                All Messages
              </NuxtLink>
            </template>
          </a-list>
        </a-config-provider>
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts" setup>
import { tagNoticeReadApi } from '@/api';
import emptyImg from '@/assets/img/no-data.png';
import { useNotice } from '@/stores/notice';
import moment from 'moment';

const noticeStore = useNotice();

const isLoading = ref(false);
const handleGetNoticeList = async () => {
  try {
    isLoading.value = true;
    await noticeStore.updateCount();
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};

const noticeShow = ref(false);
watch(
  () => noticeShow.value,
  (val: boolean) => {
    if (val) {
      handleGetNoticeList();
    }
  },
);
const handleCloseNotice = () => {
  noticeShow.value = false;
};

const handleNoticeClick = async (item: any) => {
  try {
    isLoading.value = true;
    await tagNoticeReadApi({
      id: item.id,
      type: item.type,
    });
    noticeStore.updateCount();
    handleCloseNotice();
  } catch (error) {
    console.warn(error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  nextTick(() => {
    noticeStore.updateCount();
  });
});
</script>

<style lang="less">
.notice-message-list-popover {
  min-width: 300px;
  border-radius: 12px;
  .empty-img {
    .ant-empty-image {
      height: auto;
      img {
        width: 120px;
        height: 120px;
      }
    }
  }
  .ant-popover-inner {
    border-radius: 12px;
    min-width: 360px;
    padding: 0 16px;
  }
  .notice-message-list-item {
    word-break: break-word;
  }
  .notice-message-list-item:last-of-type {
    border-block-end: none !important;
  }
}

:deep(.ant-badge-count) {
  box-shadow: none;
}
</style>
