/*
  description: localStorage存储工具函数
  author: 邓时佳
  date: 2024-03-25
*/

// 定义一个前缀, 防止和其他项目混淆
const prefix = 'ArtiMind';

export const storage = {
  // 设置localStorage的值
  set(key: string, value: any) {
    const _key = `${prefix}_${key}`;

    // 将value对象转换为JSON字符串进行存储
    localStorage.setItem(_key, JSON.stringify(value));
  },

  // 获取localStorage的值
  get(key: string) {
    const _key = `${prefix}_${key}`;
    const value = localStorage.getItem(_key);

    // 将存储的JSON字符串转换回原来的数据类型
    try {
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error(error);
      return value;
    }
  },

  // 删除localStorage的值
  remove(key: string) {
    const _key = `${prefix}_${key}`;

    localStorage.removeItem(_key);
  },

  setGenerateCache(value: any) {
    this.set('generateCache', value);
  },

  getGenerateCache() {
    const data = this.get('generateCache');

    if (data && data.curSelectScene) {
      return data;
    }

    return null;
  },

  clearGenerateCache() {
    this.remove('generateCache');
  },
};
