import { generateMethodMap } from '@/config/aimake/common';

// 艺术图像3d

// 样式栏目映射
export const styleTypeMap = {
  vectorgraphics: 'For Vector Designing',
  threeD: 'For 3D Effect Engraving',
  logo: 'For Logo Designing',
  imageTextMix: 'For Text & Graphics Designing',
  blackWhite: 'For Monochrome Engraving',
  cutting: 'For Blade Cutting',
  artword: 'For Typography Designing',
  color: 'For Colorful Printing',
  screenart: 'For Screen Printing',
} as any;

// style选择弹窗文案映射
export const styleSelectTextMap = {
  [styleTypeMap.vectorgraphics]: 'Vector',
  [styleTypeMap.threeD]: '3D engraving',
  [styleTypeMap.logo]: 'Logo',
  [styleTypeMap.imageTextMix]: 'Text & graphics',
  [styleTypeMap.blackWhite]: 'Monochrome',
  [styleTypeMap.cutting]: 'Blade Cutting',
  [styleTypeMap.artword]: 'Typography',
  [styleTypeMap.color]: 'Colorful printing',
  [styleTypeMap.screenart]: 'Screen printing',
} as any;

// 刀切
export const cuttingStyleList = [
  {
    name: 'Hollow art',
    name_zh: '镂空艺术',
    key: 'Hollow art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/cutting/HollowArt.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.cutting,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Visual arts',
    name_zh: '视觉艺术',
    key: 'Visual arts',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/cutting/VisualArts.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.cutting,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Minimalist silhouette',
    name_zh: '简约剪影',
    key: 'Minimalist silhouette',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/cutting/MinimalistSilhouette.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.cutting,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Colorful stickers',
    name_zh: '多彩贴纸',
    key: 'Colorful stickers',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/cutting/ColorfulStickers.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.cutting,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Datura',
    name_zh: '曼陀罗',
    key: 'Datura',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/cutting/Datura.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.cutting,
    advanced: false,
    isVector: true,
  },
];

// 图文混排
export const imageTextMixStyleList = [
  {
    name: 'Creative logo',
    name_zh: '创意logo',
    key: 'Creative logo',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/CreativeLogo.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
  },

  {
    name: 'Hand-drawn badge',
    name_zh: '手绘徽章',
    key: 'Hand-drawn badge',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/HandDrawnBadge.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
  },
  {
    name: 'Sports team logo',
    name_zh: '运动队徽章',
    key: 'Sports team logo',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/SportsTeamLogo.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix',
  },
  {
    name: 'Cutout logo',
    name_zh: '镂空徽标',
    key: 'Cutout logo',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/CutoutLogo.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Retro printing',
    name_zh: '复古印刷',
    key: 'Retro printing',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/RetroPrinting.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },
  {
    name: 'Cursive script',
    name_zh: '复古草书',
    key: 'Cursive script',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/CursiveScript.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Punk',
    name_zh: '朋克',
    key: 'Punk',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/Punk.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Pencil sketch',
    name_zh: '铅笔素描',
    key: 'Pencil sketch',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/PencilSketch.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.logo,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Classic quote',
    name_zh: '经典语录',
    key: 'Classic quote',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/ClassicQuote.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText2',
    prompts: 'imageTextMix2',
    logo_text_length: 40,
  },

  {
    name: 'Coloring book',
    name_zh: '上色卡',
    key: 'Coloring book',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/ColoringBook.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: '2D silhouette',
    name_zh: '平面剪影',
    key: '2D silhouette',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/2DSilhouette.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },

  {
    name: 'Street art',
    name_zh: '街头艺术',
    key: 'Street art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/imageTextMix/StreetArt.jpg',
    scene: generateMethodMap.imageTextMix,
    type: styleTypeMap.imageTextMix,
    advanced: false,
    logo_text: 'logoText',
    prompts: 'imageTextMix2',
  },
];

// 艺术字
export const artwordStyleList = [
  {
    name: 'Split monogram',
    name_zh: '分割字母',
    key: 'Split monogram',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/SplitMonogram.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Flame',
    name_zh: '激情火焰',
    key: 'Flame',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/Flame.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Ellipse',
    name_zh: '椭圆字体',
    key: 'Ellipse',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/Ellipse.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Planet',
    name_zh: '星球',
    key: 'Planet',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/Planet.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: '3D',
    name_zh: '3D',
    key: '3D',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/3D.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Handmade poster',
    name_zh: '手工海报',
    key: 'Handmade poster',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/HandmadePosters.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Retro & noble',
    name_zh: '复古贵族',
    key: 'Retro & noble',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/RetroNoble.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Pencil texture',
    name_zh: '铅笔纹理',
    key: 'Pencil texture',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/PencilTexture.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Graffiti text',
    name_zh: '涂鸦艺术字',
    key: 'Graffiti text',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/GraffitiText.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Floral text',
    name_zh: '鲜花艺术字',
    key: 'Floral text',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/FloralText.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Ink wash',
    name_zh: '水墨',
    key: 'Ink wash',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/InkWash.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText',
    prompts_length: 15,
  },

  {
    name: 'Black & white sticker',
    name_zh: '喷漆贴纸',
    key: 'Black & white sticker',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/BlackWhiteSticker.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Retro black & white',
    name_zh: '复古黑白',
    key: 'Retro black & white',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/RetroBlackWhite.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Badge',
    name_zh: '徽章',
    key: 'Badge',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/Badge.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },

  {
    name: 'Pixel',
    name_zh: '像素风格',
    key: 'Pixel',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/artword/Pixel.jpg',
    scene: generateMethodMap.artword,
    type: styleTypeMap.artword,
    advanced: false,
    prompts: 'logoText2',
    prompts_length: 40,
  },
];

// 矢量
export const vectorgraphicsStyleList = [
  {
    name: 'Line icon',
    name_zh: '线条图标',
    key: 'Line icon',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/Lineicon.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: '2D graphics',
    name_zh: '平面图形',
    key: '2D graphics',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/2Dgraphics.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Silhouette',
    name_zh: '剪影',
    key: 'Silhouette',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/Silhouette.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Stripes',
    name_zh: '条纹',
    key: 'Stripes',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/Stripes.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Geometric icon',
    name_zh: '几何图标',
    key: 'Geometric icon',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/Geometricicon.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
  {
    name: 'Fibonacci',
    name_zh: '斐波那契',
    key: 'Fibonacci',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/vector/Fibonacci.jpg',
    scene: generateMethodMap.vectorgraphics,
    type: styleTypeMap.vectorgraphics,
    advanced: false,
    isVector: true,
  },
];

// 激光艺术
export const laserartStyleList = [
  {
    name: 'Low relief',
    name_zh: '浅浮雕',
    key: 'Low relief',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/LowRelief.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Realistic',
    name_zh: '写实',
    key: 'Realistic',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Realistic.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Smoke effect',
    name_zh: '烟雾',
    key: 'Smoke effect',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/SmokeEffect.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Coat of arms',
    name_zh: '立体纹章',
    key: 'Coat of arms',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/CoatOfArms.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Wood carving',
    name_zh: '漩涡木雕',
    key: 'Wood carving',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/WoodCarving.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Steampunk',
    name_zh: '蒸汽朋克',
    key: 'Steampunk',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Steampunk.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Marble sculpture',
    name_zh: '石雕',
    key: 'Marble sculpture',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/MarbleSculpture.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Hologram',
    name_zh: '全息图',
    key: 'Hologram',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Hologram.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.threeD,
    advanced: false,
  },

  {
    name: 'Ripples',
    name_zh: '波纹',
    key: 'Ripples',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Ripples.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Geometric pattern',
    name_zh: '几何形状',
    key: 'Geometric pattern',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/GeometricPattern.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Picture book',
    name_zh: '儿童绘本',
    key: 'Picture book',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/PictureBook.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Line art',
    name_zh: '线稿艺术',
    key: 'Line art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/LineArt.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Starry night',
    name_zh: '徽章艺术',
    key: 'Starry night',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/StarryNight.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Dot matrix',
    name_zh: '点阵',
    key: 'Dot matrix',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/DotMatrix.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Medieval crest',
    name_zh: '中世纪纹章',
    key: 'Medieval crest',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/MedievalCrest.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: '3D contour',
    name_zh: '三维轮廓',
    key: '3D contour',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/3DContour.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Primitive',
    name_zh: '原始图案',
    key: 'Primitive',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Primitive.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Sharp edge',
    name_zh: '未来边锋',
    key: 'Sharp edge',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/SharpEdge.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Stained glass',
    name_zh: '彩色玻璃书',
    key: 'Stained glass',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/StainedGlass.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Block printing',
    name_zh: '雕版印刷',
    key: 'Block printing',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/BlockPrinting.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Chalk drawing',
    name_zh: '粉笔',
    key: 'Chalk drawing',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/ChalkDrawing.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Draft',
    name_zh: '草图',
    key: 'Draft',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Draft.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Geometric line',
    name_zh: '几何线条',
    key: 'Geometric line',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/GeometricLine.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Zentangle',
    name_zh: '禅绕画',
    key: 'Zentangle',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Zentangle.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.blackWhite,
    advanced: false,
  },

  {
    name: 'Folk art',
    name_zh: '民间艺术',
    key: 'Folk art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/FolkArt.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Doodle',
    name_zh: '手绘涂鸦',
    key: 'Doodle',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Doodle.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Wall art',
    name_zh: '墙绘艺术',
    key: 'Wall art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/WallArt.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: '1970s art',
    name_zh: '1970s年代',
    key: '1970s art',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/1970sArt.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Mural painting',
    name_zh: '壁画',
    key: 'Mural painting',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/MuralPainting.jpg',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.color,
    advanced: false,
  },

  {
    name: 'Forest',
    name_zh: '绿色系',
    key: 'Forest',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Forest.png',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Dusk',
    name_zh: '黄色系',
    key: 'Dusk',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Dusk.png',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Volcano',
    name_zh: '红色系',
    key: 'Volcano',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Volcano.png',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },

  {
    name: 'Midnight',
    name_zh: '蓝色系',
    key: 'Midnight',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/style/laserart/Midnight.png',
    scene: generateMethodMap.laserart,
    type: styleTypeMap.screenart,
    advanced: false,
  },
];

// style列表
export const styleList = [...imageTextMixStyleList, ...artwordStyleList, ...vectorgraphicsStyleList, ...laserartStyleList, ...cuttingStyleList];

// 消耗积分配置
export const costCreditsMap = {
  // 激光艺术
  [generateMethodMap.laserart]: {
    def: 10,
    advanced: 10,
  },
  // 矢量
  [generateMethodMap.vectorgraphics]: {
    def: 10,
    advanced: 10,
  },
  // 艺术字
  [generateMethodMap.artword]: {
    def: 10,
    advanced: 10,
  },
  // 图文混排
  [generateMethodMap.imageTextMix]: {
    def: 10,
    advanced: 10,
  },
  // 浮雕
  [generateMethodMap.relief]: {
    def: 20,
    advanced: 20,
  },
  // 肖像线稿
  [generateMethodMap.handpainted]: {
    def: 20,
    advanced: 20,
  },
};

// 生成方式配置
export const sceneOptionsMap = {
  // 激光艺术
  [generateMethodMap.laserart]: {
    imageCount: {
      list: [1, 2, 4],
      def: 2,
      show: true,
    },
  },
  // 矢量
  [generateMethodMap.vectorgraphics]: {
    imageCount: {
      list: [1, 2, 4],
      def: 2,
      show: true,
    },
  },
  // 艺术字
  [generateMethodMap.artword]: {
    imageCount: {
      list: [1, 2, 4],
      def: 2,
      show: true,
    },
    promptTitle: 'Text to display',
    promptLength: 50,
  },
  // 图文混排
  [generateMethodMap.imageTextMix]: {
    imageCount: {
      list: [1, 2, 4],
      def: 2,
      show: true,
    },
    promptTitle: 'Image description',
    promptLength: 300,
  },
  // 浮雕
  [generateMethodMap.relief]: {
    imageCount: {
      list: [1],
      def: 1,
      show: false,
    },
    uploadConfig: {
      text: 'Upload Image',
      loading: 'Image uploading',
      title: 'Crop Image',
      ratios: ['1:1', '4:7', '7:4'],
      ratioDesc: 'Please select the aspect ratio for cropping',
    },
    promptTitle: 'Description (optional)',
  },
  // 肖像线稿
  [generateMethodMap.handpainted]: {
    imageCount: {
      list: [1, 2],
      def: 1,
      show: false,
    },
    uploadConfig: {
      ratios: ['1:1', '3:4'],
      tips: 'Photo requirements: Please upload a high-resolution, close-up, frontal photo of a single individual',
    },
    promptTitle: 'Description (optional)',
  },
};

// logo布局类型配置
export const logoLayoutTypeList = [
  {
    name: 'Text below',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/LogoLayout2.png',
    key: 'Text below',
  },
  {
    name: 'Center text',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/LogoLayout3.png',
    key: 'Center text',
  },
  {
    name: 'Surrounded below',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/LogoLayout4.png',
    key: 'Surrounded below',
  },
];

// 图片比例配置
export const imageRatioOptionsMap = {
  [generateMethodMap.imageTextMix]: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  [generateMethodMap.artword]: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  [styleTypeMap.cutting]: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  '2D graphics': [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  'Line icon': [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  'Silhouette': [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  'Picture book': [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '9:16', key: '9:16', w: 25, h: 40 },
    { name: '16:9', key: '16:9', w: 40, h: 25 },
  ],
  def: [
    { name: '1:1', key: '1:1', w: 40, h: 40 },
    { name: '4:7', key: '4:7', w: 25, h: 40 },
    { name: '7:4', key: '7:4', w: 40, h: 25 },
  ],
};

// 生成方式对应的prompt引导输入
export const promptPlaceholderMap = {
  def: "Describe your image. For example, 'a bear in sunglasses'",
  [generateMethodMap.handpainted]: 'Try describing expressions and attire, such as: smiling, wearing a hat.',
  [generateMethodMap.relief]: 'Describe what is to be generated ',
  [generateMethodMap.imageTextMix]: 'Please describe the logo pattern you want.',
  [generateMethodMap.artword]: 'Enter a sentence you like, for example, I love you.',
};

// ban图提示
export const banImageTips = {
  [generateMethodMap.imageTextMix]: ['Image generation failed.', 'Points returned, please try again.'],
  [generateMethodMap.artword]: ['Image generation failed.', 'Points returned, please try again.'],
  def: ['Image blocked', 'due to inappropriate content'],
};

// 浮雕类型配置
export const reliefTypeList = [
  {
    name: 'Emboss',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/reliefType1.png',
    key: false,
  },
  {
    name: 'Deboss',
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/reliefType2.png',
    key: true,
  },
];

// 图片上传弹窗示例
export const imageUploadExamplesMap = {
  [generateMethodMap.handpainted]: {
    isShowInPop: true,
    title: 'Example photo',
    list: [
      {
        text: 'Single frontal face',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx1.png',
        icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/msg_success.png',
      },
      {
        text: 'Not a frontal face',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx2.png',
        icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/msg_error.png',
      },
      {
        text: 'Part covered',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx3.png',
        icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/msg_error.png',
      },
      {
        text: 'Too blurred',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx4.png',
        icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/msg_error.png',
      },
    ],
  },
  [generateMethodMap.relief]: {
    isShowInPop: false,
    icon: 'icon-infomation',
    title: 'Recommended to use line art images for optimal results',
    list: [
      {
        text: 'Example1',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx5.png',
        icon: '',
      },
      {
        text: 'Example2',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx6.png',
        icon: '',
      },
      {
        text: 'Example3',
        img: 'https://image-res.xtool.com/resource/xart/static/artimind/generate/uploadEx7.png',
        icon: '',
      },
    ],
  },
};

// 比例图标
export const ratioIconMap = {
  '1:1': 'icon-square',
  '3:4': 'icon-rectangle',
  '4:3': 'icon-rectangle-2',
  '4:7': 'icon-rectangle',
  '7:4': 'icon-rectangle-2',
  '9:16': 'icon-rectangle',
  '16:9': 'icon-rectangle-2',
} as any;
