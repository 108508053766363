<template>
  <a-drawer
    width="100%"
    height="80vh"
    placement="bottom"
    title="Select Asset"
    destroy-on-close
    :open="layoutStore.isActMbModalShow"
    :closable="false"
    :z-index="zIndex"
    @close="onClose"
  >
    <div>
      <div class="flex gap-4" @click="handleClose">
        <NuxtLink
          :to="projectLink"
          class="flex w-[calc(100%-8px)] flex-col items-center gap-2 rounded-xl bg-background-2 py-4 text-text-primary hover:text-text-primary"
        >
          <icon-font class="text-[24px]" type="icon-Templates" />
          <span class="whitespace-nowrap font-sans-semibold text-level-7 text-text-primary">Share a Project</span>
        </NuxtLink>
        <NuxtLink
          :to="designLink"
          class="flex w-[calc(100%-8px)] flex-col items-center gap-2 rounded-xl bg-background-2 py-4 text-text-primary hover:text-text-primary"
        >
          <icon-font class="text-[24px]" type="icon-a-30-Desing" />
          <span class="whitespace-nowrap font-sans-semibold text-level-7 text-text-primary">Share a Design</span>
        </NuxtLink>
      </div>

      <a-spin class="" :spinning="isSpinning">
        <div class="items mt-5 flex flex-wrap mobile:space-y-4" :style="{ 'gap': 'var(--gap)' }">
          <CommonActItem
            v-for="item in items"
            :key="item.id"
            ref="itemRefs"
            :item="item"
            :style="{
              width: `calc(100% / var(--w) - var(--gap)*(var(--w) - 1)/var(--w))`,
            }"
            @change="onChange(item, $event)"
          />
        </div>
      </a-spin>
    </div>

    <template #footer>
      <div class="footer sticky bottom-[0px] flex items-center justify-center gap-4 rounded-b-2xl bg-uni-6">
        <df-btn @click="handleClose">Cancel</df-btn>
        <df-btn type="primary" :disabled="selectedItems.length < 1" :loading="loading" @click="onSelect">Select</df-btn>
      </div>
    </template>

    <CommonActSuccessModal ref="modalRef" @confirm="onConfirm" />
  </a-drawer>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { useAct } from './act/hook';

type PropsType = {
  visible: boolean;
  zIndex?: number;
};
withDefaults(defineProps<PropsType>(), {
  zIndex: 1009,
});
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();

const { selectedItems, loading, onSelect, items, isSpinning, onChange, handleConfirm, modalRef } = useAct();
const layoutStore = useLayoutStore();
const route = useRoute();

const projectLink = computed(() => {
  const tag = route.query.tag ? `&tag=${route.query.tag}` : '';
  return '/share/project?mode=free' + tag;
});
const designLink = computed(() => {
  const tag = route.query.tag ? `&tag=${route.query.tag}` : '';
  return '/share/designs?mode=free' + tag;
});

const onClose = () => {
  layoutStore.setActMbModalShow(false);
};

const handleClose = () => {
  emits('update:visible', false);
  layoutStore.setActModalShow(false);
};
const onConfirm = async () => {
  await handleConfirm();
  if (items.value.length <= 0) {
    handleClose();
  }
};
</script>

<style lang="less" scoped>
.items {
  --gap: 0;
  --w: 1;
}
</style>
