import { request, REQUEST_SEVER } from '@/utils/request';

export type SearchParams = {
  current?: number;
  pageSize?: number;
  q?: string;
  cid?: string;
  field?: string;
  type?: string;
  withTotal?: boolean;
  force?: boolean;
  types?: string[];
  payTypes?: string[];
  hasInstruction?: string;
  hasAttachFile?: string;
  // freeForVip?: string;
};
export type SearchCountInfo = {
  total: number;
  howtos: number;
  inspiration: number;
  projectWithInstruction: number;
  project: number;
  designs: number;
  discussion: number;
  asset: number;
  user: number;
  query: string;
  suggest: string;
  feedback: boolean;
};

// 搜索
export const doSearchApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search',
    method: 'GET',
    params,
  });
};

/**
 * 搜索(新)
 */
export const postSearchApi = defineFindApi({
  url: '/community/v2/web/search',
  method: HttpMethod.POST,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Json<SearchParams>(),
});

/**
 * 数量搜索
 */
export const postSearchCountApi = defineFindApi({
  url: '/community/v2/web/search/count',
  method: HttpMethod.POST,
  respTy: RespTy.Json<any>(),
  paramTy: ParamTy.Json<SearchParams>(),
});

/**
 * 搜索结果反馈
 */
export const postSearchFeedbackApi = defineFindApi({
  url: '/community/v2/web/search_feedback',
  method: HttpMethod.POST,
  respTy: RespTy.Json<SearchCountInfo>(),
  paramTy: ParamTy.Json<{
    cid: string;
    q: string;
    result: string | 'yes' | 'no';
    reason?: string;
  }>(),
});

export const getSearchResultCountApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/count',
    method: 'GET',
    params,
  });
};
// 搜索热词
export const getSearchHotWordsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/hotwords',
    method: 'GET',
  });
};
// 热门搜索结果
export const getSearchHotResWordsApi = () => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/hot-results',
    method: 'GET',
  });
};
// 热门搜索结果
export const getHotListApi = defineFindApi({
  url: '/community/v1/web/search/hot-results',
  method: HttpMethod.GET,
});
// 搜索词建议
export const getSearchSuggestApi = (params: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/suggest',
    method: 'GET',
    params,
  });
};
// 搜索操作上报
export const getSearchActionApi = (data: any) => {
  return request[REQUEST_SEVER.DEFAULT]({
    url: '/community/v1/web/search/action',
    method: 'POST',
    data,
  });
};
