<template>
  <div class="aimake-header-user-credits flex-r-c">
    <a-dropdown :trigger="['hover']" overlay-class-name="aimake-login-dropdown" placement="bottom">
      <div class="aimake-user-credits flex-r-c" :class="{ click: isShowPurchase }">
        <img class="img" :src="creditsIcon" />
        <span v-show="isInit" class="text">{{ artimindAiStore.credits || 0 }}</span>
      </div>

      <template #overlay>
        <div v-if="isShowPurchase" class="aimake-user-pop">
          <span class="item" @click="handlePurchase">
            <icon-font type="icon-a-Purchasecredits" />
            Purchase credits
          </span>
        </div>
      </template>
    </a-dropdown>
  </div>
</template>
<script setup lang="ts">
import creditsIcon from '@/assets/img/aimake/credits_icon.png';
import { useArtimindAiStore } from '@/stores/aimake';
import { SubscriptionStatus } from '~/components/subscription/constant';
import { useSensors } from '~/composables/sensors/aimake';
import { useFeedbackModal, useSubscription } from '~/stores/subscription';

const { sensorExposeSubsModal } = useSensors();

const { isLogin } = useLoginStatus();
const feedbackModal = useFeedbackModal();
const artimindAiStore = useArtimindAiStore();
const subscription = useSubscription();

const isInit = ref(false);

const isShowPurchase = computed(() => {
  // 会员处于激活状态
  if (subscription.status === SubscriptionStatus.ACTIVE) {
    return false;
  }

  return false;
});

const handlePurchase = () => {
  const traceInfo = {
    source: 'generate',
    is_free: subscription.isTrial ? 1 : 0,
    level: subscription.plan,
    subs_status: subscription.status,
  };

  const sensorParams = {
    ...traceInfo,
    name: 'headerPurchaseCredits',
    is_modal: 1,
  };

  subscription.openBuyOrSubscribeModal({
    title: 'Buy more credits',
    scene: 'AImake_credits',
    traceInfo,
  });

  sensorExposeSubsModal(sensorParams);
};

watch(
  () => feedbackModal.status,
  (val) => {
    if (['succeed', 'failed'].includes(val)) {
      isLogin.value && artimindAiStore.getCredits();
    }
  },
  {
    immediate: true,
  },
);

onMounted(async () => {
  isLogin.value && (await artimindAiStore.getCredits());
  isInit.value = true;
});
</script>

<style lang="less" scoped>
.aimake-header-user-credits {
  margin-left: 16px;
  min-width: 62px;
  height: 40px;

  .img {
    width: 20px;
    height: 20px;
  }

  .text {
    color: #575757;
    font-size: 14px;
  }

  .aimake-user-credits {
    padding: 0px 12px;
    height: 32px;
    gap: 4px;
    border-radius: 8px;
    transition: all 0.2s;

    &.click {
      cursor: pointer;

      &:hover {
        background-color: #f5f7fb;
      }
    }
  }
}
</style>

<style lang="less">
.aimake-login-dropdown {
  padding-top: 20px;
  .aimake-user-pop {
    display: flex;
    flex-direction: column;
    width: 186px;
    padding: 2px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow:
      0 1px 2px 0 rgba(0, 0, 0, 0.06),
      0 1px 20px 0 rgba(0, 0, 0, 0.1);

    .item {
      display: inline-flex;
      align-items: center;
      padding: 7px 12px;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: #212121;
      cursor: pointer;

      &:hover {
        color: #1a1a1a;
        background: #f5f7fb;
      }

      &:first-child:hover {
        border-radius: 10px 10px 0 0;
      }

      &:last-child:hover {
        border-radius: 0 0 10px 10px;
      }

      .anticon {
        margin-right: 12px;
        font-size: 20px;
      }
    }
  }
}
</style>
