<template>
  <a-modal
    destroy-on-close
    :width="1000"
    :open="visible"
    :z-index="zIndex"
    wrap-class-name="act-create-modal-box"
    :footer="null"
    centered
    :closable="false"
    @cancel="handleClose"
  >
    <div class="px-8 pt-6">
      <div class="max-h-[600px] overflow-auto">
        <div class="font-sans-bold text-[24px] text-text-primary">{{ title }}</div>
        <div :class="['share-box mb-10 rounded-xl border']">
          <div class="mt-5 flex gap-4">
            <CreateItem v-for="item in actCardList" :key="item.title" :create-item="item" :mode="mode" class="flex-1" @click="handleClose" />
          </div>
        </div>

        <a-spin class="" :spinning="isSpinning">
          <div class="font-sans-bold text-[24px] text-text-primary">Select Asset</div>
          <div class="items mt-5 flex flex-wrap" :style="{ 'gap': 'var(--gap)' }">
            <CommonActItem
              v-for="item in items"
              :key="item.id"
              ref="itemRefs"
              :item="item"
              :style="{
                width: `calc(100% / var(--w) - var(--gap)*(var(--w) - 1)/var(--w))`,
              }"
              @change="onChange(item, $event)"
            />
          </div>
        </a-spin>
      </div>
    </div>
    <div class="footer sticky bottom-[0px] flex items-center justify-center gap-4 rounded-b-2xl bg-uni-6 py-3">
      <df-btn @click="handleClose">Cancel</df-btn>
      <df-btn type="primary" :disabled="selectedItems.length < 1" :loading="loading" @click="onSelect">Select</df-btn>
    </div>
    <CommonActSuccessModal ref="modalRef" @confirm="onConfirm" />
  </a-modal>
</template>

<script setup lang="ts">
import { useLayoutStore } from '@/stores/layout';
import { useAct } from './act/hook';
import { actCardList } from './const';
import CreateItem from './createItem.vue';

type PropType = {
  visible: boolean;
  zIndex?: number;
};
withDefaults(defineProps<PropType>(), {
  zIndex: 1006,
});
type EmitsType = {
  (event: 'update:visible', value: boolean): void;
};
const emits = defineEmits<EmitsType>();

const { title, selectedItems, loading, modalRef, onSelect, items, isSpinning, onChange, handleConfirm, itemRefs } = useAct();
const layoutStore = useLayoutStore();

const mode = ref('free' as 'sale' | 'free');

const handleClose = () => {
  layoutStore.setActModalShow(false);
  emits('update:visible', false);
};
const onConfirm = async () => {
  await handleConfirm();
  if (items.value.length <= 0) {
    handleClose();
  }
};
</script>

<style lang="less">
.act-create-modal-box {
  .ant-modal .ant-modal-body {
    padding: 0;
  }
}
</style>
<style lang="less" scoped>
.items {
  --gap: 12px;
  --w: 4;
}
</style>
