import design from '~/assets/img/design-modal.png';
import project from '~/assets/img/project-model.jpg';

export const actCardList = [
  {
    title: 'Projects',
    link: '/share/project',
    img: project,
    btnText: 'Share a Project',
    textList: [
      {
        text: 'Design Files',
        check: true,
      },
      {
        text: 'Settings & Parameters',
        check: true,
      },
      {
        text: 'Detail Instruction (Optional)',
        check: true,
      },
    ],
    freePoint: '+500 Points',
    hoverText:
      'Projects should include the entire process, from initial design concept, through the making process, to the final presentation of the completed product.',
  },
  {
    title: 'Designs',
    link: '/share/designs',
    img: design,
    btnText: 'Share a Design',
    textList: [
      {
        text: 'Design Files',
        check: true,
      },
      {
        text: 'Settings & Parameters',
        check: false,
      },
      {
        text: 'Detail Instruction',
        check: false,
      },
    ],
    freePoint: '',
    hoverText: 'Designs are universal design elements that can be used for all types of crafting.',
  },
];
