<template>
  <div class="df-share">
    <df-btn type="special" size="large" @click="checkIsLogin">Share</df-btn>
    <ClientOnly>
      <LazyCommonDraftModal v-model:visible="draftModalVisible" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useUserInfo } from '@/stores/user';
import { useLayoutStore } from '~/stores/layout';

const { isLogin } = useLoginStatus();
const { $client } = useNuxtApp();
const checkIsLogin = () => {
  if (isLogin.value) {
    handleCreateClick();
    return;
  }
  $client.openModal(() => {
    location.reload();
  });
};

// const handleShareButtonClick = () => {
// };
const { sensorShareMyMakingClickEvent } = useSensors();
const userInfoStore = useUserInfo();

// 是否是白名单用户
const isSaleMaker = computed(() => Boolean(userInfoStore.isSaleMaker));
const draftModalVisible = ref(false);
const layoutStore = useLayoutStore();
const handleCreateClick = () => {
  sensorShareMyMakingClickEvent({ login_status: isLogin.value });

  if (checkIsAtivity()) {
    layoutStore.setIsShowNewProject(true, {
      disableTab: true,
    });
  } else {
    layoutStore.openPcEditorEntryModal(isSaleMaker.value);
  }
};
</script>

<style scoped lang="less">
.share-pop {
  display: flex;
  flex-direction: column;
  padding: 2px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow:
    0 1px 2px 0 rgba(0, 0, 0, 0.06),
    0 1px 20px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;

  a {
    display: inline-flex;
    align-items: center;
    padding: 7px 12px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #212121;

    &:hover {
      color: #1a1a1a;
      background: #f5f7fb;
    }

    &:first-child:hover {
      border-radius: 10px 10px 0 0;
    }

    &:last-child:hover {
      border-radius: 0 0 10px 10px;
    }

    .anticon {
      margin-right: 12px;
      font-size: 20px;
    }
  }
}
</style>
