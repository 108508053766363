// 生成图片方式对应的方法名字典配置，用于前端和后台进行映射逻辑
export const generateMethodMap = {
  laserart: 'LaserArt', // 激光艺术
  vectorgraphics: 'VectorArt', // 矢量
  imageTextMix: 'ImageTextMix', // 图文混排
  artword: 'CreativeText', // 艺术字
  handpainted: 'FaceSketch', // 肖像线稿
  relief: 'Relief', // 浮雕
  artimage: 'Artistic', // 艺术图像 MJ使用，暂不支持
  screenart: 'ScreenPrintArt', // 丝网
};

// 生成图片方式对应页面文案的字典配置, 用于页面展示
export const generateMethodTextMap = {
  [generateMethodMap.relief]: 'Embossment',
  [generateMethodMap.handpainted]: 'Face sketch',
};

// 首页生成工具列表
export const homeMethodList = [
  {
    icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/faceMethod.png',
    name: generateMethodTextMap[generateMethodMap.handpainted],
    key: generateMethodMap.handpainted,
    desc: 'Converting portraits into face sketches',
  },
  {
    icon: 'https://image-res.xtool.com/resource/xart/static/artimind/common/reliefMethod.png',
    name: generateMethodTextMap[generateMethodMap.relief],
    key: generateMethodMap.relief,
    desc: 'Generating a depth map for Embossment processing',
    label: 'For F1 Ultra',
  },
];

// 热门列表
export const homePopularList = [
  {
    name: 'Creative logo',
    name_zh: '创意logo',
    style: 'Creative logo',
    scene: generateMethodMap.imageTextMix,
    img_a: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular1_a.jpg',
    img_b: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular1_b.jpg',
    type: 'Vector',
    // video: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular1.mp4',
  },
  {
    name: 'Low relief',
    name_zh: '浅浮雕',
    style: 'Low relief',
    scene: generateMethodMap.laserart,
    img_a: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular2_a.jpg',
    img_b: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular2_b.jpg',
    type: '3D Effect',
    // video: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular2.mp4',
  },
  {
    name: 'Geometric line',
    name_zh: '几何线条',
    style: 'Geometric line',
    scene: generateMethodMap.laserart,
    img_a: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular3_a.jpg',
    img_b: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular3_b.jpg',
    type: 'Monochrome',
    // video: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular3.mp4',
  },
  {
    name: 'Embossment',
    name_zh: '深浮雕',
    style: '',
    scene: generateMethodMap.relief,
    img_a: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular4_a.jpg',
    img_b: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular4_b.jpg',
    label: 'For F1 Ultra',
    // video: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular4.mp4',
  },
  {
    name: 'Picture book',
    name_zh: '儿童绘本',
    style: 'Picture book',
    img_a: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular5_a.jpg',
    img_b: 'https://image-res.xtool.com/resource/xart/static/artimind/popular/popular5_b.jpg',
    scene: generateMethodMap.laserart,
    type: 'Monochrome',
    // video: 'https://mbeastus.blob.core.windows.net/resource/xart/static/artimind/popular/popular5.mp4',
  },
];

// 热门列表icon
export const homePopularIcon = 'https://image-res.xtool.com/resource/xart/static/artimind/common/popular_icon.png';

// 历史记录失败状态文案映射
export const historyErrorTipsMap = {
  '1000': 'Sorry. The image generation failed due to the lack of facial recognition.',
  'server': 'Sorry. The image generation failed due to server exceptions.',
} as any;

// 异常页面的场景配置映射
export const errorPageSceneConfigMap = {
  // 网络异常
  network: {
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/common/network_error.png',
    imgSize: { w: 100, h: 100 },
    desc: ['Network abnormality, please check the network connection'],
    button: 'Refresh',
  },
  // 服务异常
  server: {
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/common/server_error.png',
    imgSize: { w: 150, h: 100 },
    desc: ['The server is out of service, please try again later.'],
    button: 'Reload',
  },
  // 未登录
  login: {
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/common/login_error.png',
    imgSize: { w: 150, h: 100 },
    desc: ['You can view your image generation history after logging in.'],
  },
  // 历史记录为空
  historyEmpry: {
    img: 'https://image-res.xtool.com/resource/xart/static/artimind/common/empty_error.png',
    imgSize: { w: 100, h: 100 },
    desc: ["You haven't used the image generation function before.", "Click 'Generate' to have a try."],
  },
} as any;

// 历史记录状态映射
export const historyStatusMap = {
  line: 1, // 排队中
  pending: 2, // 生成中
  success: 3, // 成功
  fail: 4, // 失败
};

// 教程文档映射
export const helpIconUrlMap = {
  [generateMethodMap.artimage]: 'https://support.xtool.com/article/869',
  [generateMethodMap.artword]: 'https://support.xtool.com/article/1678',
  [generateMethodMap.imageTextMix]: 'https://support.xtool.com/article/1679',
  [generateMethodMap.handpainted]: 'https://support.xtool.com/article/1309?from=search&q=AImake%20face',
  'def': 'https://support.xtool.com/article/1679',
};
