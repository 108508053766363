import { doPostApi, getActivityMakingOptionalListApi } from '@/api/activity';
import { useLayoutStore } from '@/stores/layout';

export const useAct = () => {
  const layoutStore = useLayoutStore();

  const selectedItems = ref<number[]>([]);

  const title = computed(() => {
    return layoutStore.actModalOpts?.title ?? '';
  });
  const activityId = computed(() => {
    return layoutStore.actModalOpts?.activityId ?? 0;
  });
  // 第一次使用传进来的items, 投稿后，需要更新列表，此时使用list
  const items = computed(() => {
    if (list.value !== null) {
      return list.value;
    }
    return layoutStore.actModalOpts?.items ?? [];
  });

  const modalRef = ref<any>(null);
  function showSuccessModal() {
    modalRef.value?.open();
  }

  const loading = ref(false);
  async function onSelect() {
    try {
      loading.value = true;
      const { code } = await doPostApi.fetch({
        activityId: activityId.value,
        makingId: selectedItems.value[0],
      });
      if (code === 0) {
        showSuccessModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.value = false;
    }
  }

  const itemRefs = ref<any[]>([]);
  function onChange(item: any, opts: Record<string, any>) {
    const id = item.id;
    // console.log('itemRefs.value :>> ', itemRefs.value);
    // console.log('opts :>> ', opts);
    if (opts.checked) {
      selectedItems.value = [id];
      reset(id);
    } else {
      selectedItems.value = [];
      reset();
    }
  }
  function reset(id?: number) {
    itemRefs.value?.forEach((v) => {
      if (typeof v.reset === 'function') {
        v.reset(id);
      }
    });
  }

  const list = ref<any[] | null>(null);
  // 更新列表，清空选项
  const isSpinning = ref(false);
  async function handleConfirm() {
    reset();
    selectedItems.value = [];
    try {
      isSpinning.value = true;
      const { data } = await getActivityMakingOptionalListApi.fetch({
        current: 1,
        pageSize: -1,
        id: activityId.value,
      });
      list.value = data?.list ?? [];
    } catch (err) {
      console.log('err :>> ', err);
    } finally {
      isSpinning.value = false;
    }
  }

  return {
    title,
    onSelect,
    selectedItems,
    loading,
    activityId,
    modalRef,
    itemRefs,
    onChange,
    isSpinning,
    list,
    items,
    handleConfirm,
  };
};
