<template>
  <div class="df-nav ml-[16px]">
    <DfMenu v-model:open-key="openKey" :offset="14" class="h-[40px]">
      <DfMenuItem
        :name="'Project'"
        :title="'Projects'"
        :to="'/all?types=project&sortType=hottest&page=1'"
        :class="[activeKey === 'Project' ? 'active' : '']"
        @click.prevent="navigateTo('/all?types=project&sortType=hottest&page=1', 'Project')"
      >
        <template v-if="config.projects" #detail>
          <NavDetail :config="config.projects" />
        </template>
      </DfMenuItem>
      <DfMenuItem
        :name="'Designs'"
        :to="'/all?types=designs&sortType=hottest&page=1'"
        :class="[activeKey === 'Designs' ? 'active' : '']"
        @click.prevent="navigateTo('/all?types=designs&sortType=hottest&page=1', 'Designs')"
      >
        <template v-if="config.designs" #detail>
          <NavDetail :config="config.designs" />
        </template>
      </DfMenuItem>
      <DfMenuItem
        :name="'Premium'"
        :to="'/premium'"
        :class="[activeKey === 'Premium' ? 'active' : '', 'premium']"
        @click.prevent="navigateTo('/premium', 'Premium')"
      >
        <template #icon>
          <img :src="PREMIUM_ICON" alt="" class="mr-1 h-5 w-5" />
        </template>
        <template v-if="config.premium" #detail>
          <NavDetail :config="config.premium" />
        </template>
      </DfMenuItem>

      <DfMenuItem
        :name="'AImake'"
        :to="'/aimake'"
        :class="[activeKey === 'AImake' ? 'active' : '', 'show-in-1200']"
        @click.prevent="handleClickAiMake()"
      />

      <DfMenuItem
        :name="'Forum'"
        :to="'/discussion'"
        :class="[activeKey === 'Forum' ? 'active' : '', 'show-in-1200']"
        @click.prevent="navigateTo('/discussion', 'Forum')"
      />
    </DfMenu>

    <ClientOnly>
      <a-popover v-model:open="isShowMoreMenu" trigger="hover" placement="bottomLeft" overlay-class-name="df-menu-more-popover">
        <template #content>
          <div class="more-menu-list flex-r-c">
            <div class="item" @click.prevent="handleClickMoreMenuItem('/aimake', 'AImake')">AImake</div>
            <div class="item" @click.prevent="handleClickMoreMenuItem('/discussion', 'Forum')">Forum</div>
          </div>
        </template>
        <div class="more">
          <IconFont type="icon-more-fill" />
        </div>
      </a-popover>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { checkHasClickAImakeTab } from '@/utils/aimake/index';
import PREMIUM_ICON from 'assets/img/premium-icon.svg';
import NavDetail from './NavDetail.vue';
import { useMenuCtx, useNav, useNavConfig } from './hooks';
import DfMenu from './menu/DfMenu.vue';
import DfMenuItem from './menu/DfMenuItem.vue';

const { isLogin } = useLoginStatus();
const { navigateTo } = useNav();
const config = await useNavConfig();

const { openKey } = useMenuCtx();
const activeKey = ref('');
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    if (route.path === '/all' && route.query.types && route.query.types.includes('project')) {
      activeKey.value = 'Project';
    } else if (route.path === '/all' && route.query.types && route.query.types.includes('designs')) {
      activeKey.value = 'Designs';
    } else if (route.path === '/contest') {
      activeKey.value = 'Contests';
    } else if (route.path === '/premium') {
      activeKey.value = 'Premium';
    } else if (route.path === '/discussion') {
      activeKey.value = 'Forum';
    } else if (route.path === '/aimake' || route.path === '/aimake/home') {
      activeKey.value = 'AImake';
    } else {
      activeKey.value = '';
    }
  },
  { immediate: true },
);

const isShowMoreMenu = ref(false);
const handleClickMoreMenuItem = (path: string, name: string) => {
  navigateTo(path, name);
  isShowMoreMenu.value = false;
};

const handleClickAiMake = () => {
  const fnNot = () => {
    navigateTo('/aimake', 'aimake');
  };

  const fnIs = () => {
    navigateTo('/aimake/home', 'aimake-home');
  };

  // 未登录情况，统一跳转落地页
  if (!isLogin.value) {
    fnNot();
    return;
  }

  // 已登录情况，判断是否有点击过AImake tab
  checkHasClickAImakeTab(fnNot, fnIs);
};
</script>

<style scoped lang="less">
.df-nav {
  width: calc(100% - 221px);
  display: flex;
  flex-direction: row;
  align-items: center;

  :deep(.df-menu) {
    overflow-x: auto; /* 保持可滚动 */
    scrollbar-width: none; /* 对于 Firefox 6 */

    &::-webkit-scrollbar {
      display: none; /* 对于 Chrome, Safari 和 Opera */
    }
  }

  .more {
    padding: 4px 8px;
    cursor: pointer;
    line-height: 20px;
    font-size: 16px;
    transition: all 0.2s;
    border-radius: 100px;
    margin-left: 8px;
    display: none;

    &:hover {
      background: #f5f7fb;
    }
  }
}

:deep(.df-menu-item) {
  position: relative;
  .title {
    position: relative;
    white-space: nowrap;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 20%;
      min-width: 16px;
      height: 4px;
      border-radius: 12px;
      background: #ff0035;
      opacity: 0;
      transition: opacity 0.2s;
      pointer-events: none;
    }
  }
  &.active,
  &.active:hover {
    color: #1a1a1a;
    background: transparent;

    .title::after {
      opacity: 1;
    }
  }
  &.premium {
    &.active {
      .title {
        color: #db9440;
        &::after {
          background: #db9440;
        }
      }
      .anticon {
        color: #db9440;
      }
      &:hover .title::after {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 1200px) {
  .df-nav {
    .more {
      display: block;
      margin-right: 8px;
    }

    .show-in-1200 {
      display: none;
    }
  }
}
</style>

<style lang="less">
.df-menu-more-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 0px;
  }

  .more-menu-list {
    display: flex;
    flex-direction: column;
    padding: 2px;

    .item {
      min-width: 98px;
      padding: 12px;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-family: 'Open Sans SemiBold', sans-serif;
      line-height: 20px;
      color: #575757;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: #f5f7fb;
      }
    }
  }
}
</style>
