<template>
  <NuxtLink :to="`${createItem.link}${queryParams}`" class="block" @click="handleItemClick">
    <a-card hoverable :class="['draft-card relative', mode === 'sale' ? 'sale' : '']">
      <div v-if="mode === 'free' && createItem.freePoint" class="point absolute right-4 top-2 p-3 text-[12px] text-status-alert">
        {{ createItem.freePoint }}
      </div>
      <img v-if="mode === 'sale'" :src="prime" alt="" class="absolute left-0 top-0 w-[88px]" />
      <div class="create-box">
        <div class="title">{{ createItem.title }}</div>
        <div class="relative mb-3 w-full overflow-hidden">
          <img :src="createItem.img" alt="" class="h-[200px] max-w-full rounded-[10px]" />
          <div class="hover-text absolute top-0 flex h-[200px] items-center rounded-lg bg-[#000]/80 px-7 text-center text-[#fff]">
            {{ createItem.hoverText }}
          </div>
        </div>
        <div class="flex gap-3">
          <div v-for="item in createItem.textList" :key="item.text" class="mb-6 flex items-center gap-1">
            <icon-font :type="item.check ? 'icon-success-fill' : 'icon-Frame1'" />
            <span class="text-level-7 text-text-tertiary">{{ item.text }}</span>
          </div>
        </div>
        <df-btn class="btn" type="special">{{ createItem.btnText }}</df-btn>
      </div>
    </a-card>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { useLayoutStore } from '@/stores/layout';
import qs from 'qs';
import prime from '~/assets/img/prime-modal.png';

type PropsType = {
  createItem: any;
  mode: 'free' | 'sale';
};
const props = defineProps<PropsType>();
const { mode } = toRefs(props);
const { sensorsMakingOptionClickEvent } = useSensors();
const layoutStore = useLayoutStore();
const route = useRoute();

/**
 * 路径参数处理，
 * 1. 活动页需要带上tag参数;
 * 2. 新增案例需要带上mode参数;
 * 3. 带xcs文件新建，需要带上source参数;
 */
const queryParams = computed(() => {
  let queryObj: any = {};
  if (route.query.tag) {
    queryObj.tag = route.query.tag;
  }
  if (mode.value) {
    queryObj.mode = mode.value;
  }
  if (route.query.source) {
    queryObj.source = route.query.source;
  }
  return `?${qs.stringify(queryObj)}`;
});

const handleItemClick = () => {
  const item = props.createItem;

  sensorsMakingOptionClickEvent({
    service_type: item.type,
    is_draft: false,
    content_type: props.mode,
  });

  const link = layoutStore.remixBaseOnLink;
  if (link) {
    const remixBaseOnInfo = {
      desc: '用于存储二次创作的来源链接',
      value: link,
    };
    window.sessionStorage.setItem('remixBaseOnInfo', JSON.stringify(remixBaseOnInfo));
  }
};
</script>

<style lang="less" scoped>
:deep(.ant-card .ant-card-body) {
  padding: 24px 16px;
}
.draft-card {
  position: relative;
  transition: all 0.3s;
  border-radius: 12px;
  border-width: 0;
  box-shadow: 0px 0px 1px 0px rgba(96, 96, 96, 0.1);
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.05);
  .point {
    background: url('../../assets/img/create.svg') no-repeat center;
    background-size: contain;
  }
  .create-box {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 18px;
      font-family: 'Open Sans SemiBold';
      margin-bottom: 24px;
      height: 24px;
    }
  }
  &.sale {
    background: linear-gradient(180deg, rgba(255, 241, 215, 0.7), rgba(255, 241, 215, 0));
  }
  .hover-text {
    display: none;
  }
  &:hover {
    box-shadow:
      0px 0px 10px 0px rgba(0, 0, 0, 0.1),
      0px 0px 1px 0px rgba(0, 0, 0, 0.06);
    .hover-text {
      display: flex;
      transition: all 0.2s;
    }
  }
}
</style>
