import { storage } from './storage';

export const clone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

// 是否点击过AI制作tab
export const checkHasClickAImakeTab = (fnNot?: () => void, fnIs?: () => void) => {
  const flag = storage.get('hasClickAImakeTab') as number;

  if (flag === 1) {
    fnIs && fnIs();
    return;
  }

  fnNot && fnNot();
};
